import React from "react"
import Seo from "../components/Seo/seo"
import Layout from "../components/Layout/layout";
import {graphql, Link} from 'gatsby'
import styled from 'styled-components'
import Paragraph from "../components/shared/paragraph";
import media, {defaultBreakpoints, DefaultBreakpoints} from 'styled-media-query'

const StyledBody = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  
  ${media.lessThan('medium')`
    display: block;
  `}
`

const Announcements = styled(({items, className}: {items: {to: string, text: string, date: string}[], className}) => {
  const itemElements = items.map(item => <li><span className={`${className}__date`} style={{display: 'block'}}>{item.date}</span>{item.text}</li>)

  return (
    <ul className={className}>
      { itemElements }
    </ul>
  )
})`
  margin-left: 0;

  &__date {
    margin-right: 12px;
    display: block;
  }
  
  > li {
    display: block;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 8px;
    border-bottom: thin solid #eee;
    
    > a {
      color: #000;
      text-decoration: underline;
      font-size: .9em;
      
      &:hover {
        text-decoration: none;
      }
    }
    
    &:last-child {
      border-bottom: none;
    }
  }

`

const StyledAnnouncements = styled(Announcements)`
`

export default function Home({data}) {
  const sliderItems = [
    {
      to: '/about-us',
      fluid: [
        data.aboutUsCoverMobile.childImageSharp.fluid,
        {
          ...data.aboutUsCoverDesktop.childImageSharp.fluid,
          media: `(min-width: ${defaultBreakpoints.medium})`
        }
      ],
      text: 'Who we are'
    },
    {
      to: '/services',
      fluid: [
        data.servicesCoverMobile.childImageSharp.fluid,
        {
          ...data.servicesCoverDesktop.childImageSharp.fluid,
          media: `(min-width: ${defaultBreakpoints.medium})`
        }
      ],
      text: 'What we can do'
    },
    {
      to: '/contact-us',
      fluid: [
        data.contactUsCoverMobile.childImageSharp.fluid,
        {
          ...data.contactUsCoverDesktop.childImageSharp.fluid,
          media: `(min-width: ${defaultBreakpoints.medium})`
        }
      ],
      text: 'How to reach us'
    }
  ]

  const announcements = data.announcements.edges.map(edge => ({
    to: edge.node.frontmatter.slug,
    text: edge.node.frontmatter.title,
    date: edge.node.frontmatter.date,
  }))

  return (
    <>
      <Seo slug="" />
      <Layout isHome={true}>
        <StyledBody>
          <Paragraph>
            The site is under renewal by the business
          </Paragraph>
        </StyledBody>
      </Layout>
    </>
  )
}

export const query = graphql`
  query HomeIndexQuery {
    aboutUsCoverDesktop: file(relativePath: { eq: "about-us-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aboutUsCoverMobile: file(relativePath: { eq: "about-us-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    servicesCoverDesktop: file(relativePath: { eq: "services-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    servicesCoverMobile: file(relativePath: { eq: "services-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    contactUsCoverDesktop: file(relativePath: { eq: "contact-us-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    contactUsCoverMobile: file(relativePath: { eq: "contact-us-cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    announcements: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "announcement" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
          }
        }
      }
    }
  }
`
